/* Sidebar CSS */
.sidebar {
    width: 250px;
    background-color: #34b78f;
    height: 100vh;
    transition: width 0.3s ease;
}

.sidebar.collapsed {
    width: 80px;
}

.sidebar-header {
    text-align: center;
}

.sidebar-header img.logo {
    height: 60px;
}

.sidebar-header h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 10px 0 5px;
    color: white;
}

.sidebar-header p {
    font-size: 0.9rem;
    color: #ffffff;
}

.divider {
    border: 1px solid #000;
    width: 90%;
    margin: 10px auto;
}

.sidebar .nav-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1rem;
    color: #ffffff;
    font-weight: bold;
}

.sidebar .nav-link i {
    margin-right: 12px;
}

.sidebar .nav-link:hover {
    background-color: #f5f5f5;
    color: #d9534f;
}

.sidebar .nav-link .arrow {
    margin-left: auto;
}

.sidebar .sub-link {
    padding-left: 36px;
    font-size: 0.9rem;
}

.collapse {
    padding-left: 16px;
}

/* Testing Mode Toggle */
.sandbox-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    margin-top: 10px;
    border-radius: 10px;
}

.sandbox-toggle .toggle-label {
    margin-left: 15px;
    font-size: 1rem;
    font-weight: bold;
    color: #333;
}

/* Testing Mode Toggle */
.sandbox-toggle-page {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    margin-top: 10px;
    border-radius: 10px;
}

.sandbox-toggle-page .toggle-label {
    margin-left: 15px;
    font-size: 1rem;
    font-weight: bold;
    color: #333;
}

/* Switch */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    border-radius: 50%;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: #4caf50;
}

input:checked+.slider:before {
    transform: translateX(26px);
}

.slider.round {
    border-radius: 10px;
}

.slider.round:before {
    border-radius: 50%;
}

/* Sidebar Toggle Button */
.sidebar-toggle {
    background-color: transparent;
    border: none;
    color: #333;
    font-size: 0.8rem;
    /* Reduce font size */
    padding: 5px;
    /* Reduce padding */
    margin: 5px;
    /* Adjust margin to make it smaller */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar-toggle:hover {
    color: #d9534f;
}

.label-text-mobile {
    display: none;
}

/* Mobile View Adjustments */
@media (max-width: 768px) {
    .sidebar {
        width: 80px;
        /* Narrower width for mobile */
        margin-right: 0px;
    }

    /* Hide label text and other elements to show only icons */
    .sidebar .label-text {
        display: none;
    }

    .sidebar .label-text-mobile {
        display: block;
    }

    .sidebar .nav-link {
        justify-content: center;
        /* Center icons */
        font-size: 1rem;
        /* Increase icon size for better visibility */
    }

    .sidebar .nav-link i {
        margin-right: 0;
    }

    .sandbox-toggle .toggle-label {
        display: none;
    }
}


.sandbox-toggle {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.test-mode-btn {
    width: 100%;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
}

.test-mode-btn.active {
    background-color: #ffc107;
    /* Green color for Testing Mode */
    color: white;
}

.test-mode-btn:not(.active) {
    background-color: #f9fbfa;
    /* Grey color for Production Mode */
    color: black;
}

.test-mode-btn:hover {
    opacity: 0.9;
}

.test-mode-btn-page {
    width: 100%;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
}

.test-mode-btn-page.active {
    background-color: #ffc107;
    /* Green color for Testing Mode */
    color: white;
}

.test-mode-btn-page:not(.active) {
    border: 2px solid #34b78f;
    /* Grey color for Production Mode */
    color: black;
}

.test-mode-btn-page:hover {
    opacity: 0.9;
}