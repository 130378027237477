/* dashboard.css */
.content {
  margin-left: 60px;
  padding: 20px;
  background-color: #f9fbfa;
}

.dashboard {
  padding-top: 20px;
  transform: scale(0.9);
}

.info-card, .order-card, .api-card {
  border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  .css-f27u7k {
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 0px;
    overflow-wrap: break-word;
    --card-bg: var(--chakra-colors-chakra-body-bg);
    background-color: var(--card-bg);
    box-shadow: var(--card-shadow);
    border-radius: var(--chakra-radii-3xl);
    color: var(--chakra-colors-chakra-body-text);
    border-width: var(--card-border-width, 0);
    border-color: var(--card-border-color);
    padding: var(--chakra-space-3);
    --card-shadow: var(--chakra-shadows-base);
    height: var(--chakra-sizes-full);
    
}

}

.info-card h2, .order-card h3, .api-card h3 {
  font-size: 1.5rem;
}

.purchase-credits-btn {
  float: left;
  border-radius: 20px;
  background-color: #34b78f;
  color: white;
  transition: 250ms;
  border: #05a680;
  transform: scale(1.1);
  padding-left: 5px;
  padding-right: 5px;
}

.purchase-credits-btn:hover {
  background-color: #f0f0f0;
  color: black;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #34b78f;
  padding-top: 20px;
  border-right: 1px solid #ddd;
}

.sidebar-header {
  text-align: center;
  margin-bottom: 20px;
}

.sidebar .logo {
  max-width: 150px;
}

.sidebar .nav-link {
  padding: 10px 20px;
  color: #ffffff;
}

.sidebar .nav-link.active {
  background-color: #f5f5f5;
  color: #d9534f;
}

.same-size-card-bottom {
  min-height: 225px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  .css-f27u7k {
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 0px;
    overflow-wrap: break-word;
    --card-bg: var(--chakra-colors-chakra-body-bg);
    background-color: var(--card-bg);
    box-shadow: var(--card-shadow);
    border-radius: var(--chakra-radii-3xl);
    color: var(--chakra-colors-chakra-body-text);
    border-width: var(--card-border-width, 0);
    border-color: var(--card-border-color);
    padding: var(--chakra-space-3);
    --card-shadow: var(--chakra-shadows-base);
    height: var(--chakra-sizes-full);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
}
}

.same-size-card {
  border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-height: 230px; 
  .css-f27u7k {
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 0px;
    overflow-wrap: break-word;
    --card-bg: var(--chakra-colors-chakra-body-bg);
    background-color: var(--card-bg);
    box-shadow: var(--card-shadow);
    border-radius: var(--chakra-radii-3xl);
    color: var(--chakra-colors-chakra-body-text);
    border-width: var(--card-border-width, 0);
    border-color: var(--card-border-color);
    padding: var(--chakra-space-3);
    --card-shadow: var(--chakra-shadows-base);
    height: var(--chakra-sizes-full);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
}
}

.small-button {
  font-size: 0.8rem;  /* Adjust the font size as needed */
  padding: 3px 8px; /* Adjust the padding as needed */
  border-radius: 60px; /* Optional: adjust the border radius */
  margin: 2px; /* Small margin to separate buttons */
  color: 05bd93;
  width: 80%;
  align-self: center;
  transition: 250ms;
  border: white;
}

.small-button:hover {
  background-color: #05a680;
  color: white;
}


.mid-button {
  font-size: 0.8rem;  /* Adjust the font size as needed */
  padding: 3px 8px; /* Adjust the padding as needed */
  border-radius: 10px; /* Optional: adjust the border radius */
  margin: 2px; /* Small margin to separate buttons */
  color: black;
  width: 80%;
  background-color: white;
  align-self: center;
  transition: 250ms;
  border: #05bd93;
  border-style: solid;
  align-self: center;
  min-height: 25px;
}

.mid-button:hover {
  background-color: #05a680;
  color: white;
}

.bolded {
  font-weight: bold;
}

.marginbottom {
  margin-bottom: 0;
}

/* Orders Section */
.orders-card {
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  background-color: #f9f9f9;
}

.card-title {
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.orders-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.orders-text {
  margin-left: 10px;
  font-size: 1rem;
}

.order-count {
  font-size: 3rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem;
}

.recent-order-section {
  margin-bottom: 20px;
}

.recent-order-title {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 10px;
}

.label {
  font-weight: bold;
  color: #333;
}

.certificate-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.view-details-btn {
  color: #ff6a4a;
  font-size: 0.9rem;
  text-align: left;
  padding: 0;
}

.view-details-btn:hover {
  color: #ff856a;
}

.all-orders-button {
  border: 1px solid #00b295;
  color: #00b295;
  background-color: transparent;
  padding: 10px 20px;
  border-radius: 10px;
  transition: all 0.3s ease;
  width: 100%;
  margin-top: 20px;
}

.all-orders-button:hover {
  background-color: #00b295;
  color: white;
}

@media (min-width: 768px) and (max-width: 1560px) {
  .App {
    margin-right: 0px;
}
  .main-layout {
    margin-left: 50px;
  }
  .content {
    margin-left: 200px;
  }
}

.statistics-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px; /* Adjust spacing */
}

.chart-card {
  flex: 1;
  min-width: 300px; /* Adjust based on screen size */
  height: 350px; /* Set a fixed height */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: none;
  padding: 15px;
  background-color: #fff;
}

/* Ensure chart fits inside the card */
.chart-card .apexcharts-canvas {
  max-width: 100%;
  max-height: 100%;
}

.sdg-container {
  margin-top: 15px;
}

.sdg-badges {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.sdg-badge {
  width: 40px; /* Adjust size as needed */
  height: 40px;
  border-radius: 8px; /* Rounded edges */
  object-fit: cover;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
